<template>
  <div id="FloorRoom">

    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.keyword" show-action placeholder="请输入学生姓名或学号查询" @search="onSearch">
        <template #action>
          <div @click="toStuSearch">查询
          </div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="300" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell @click="toDetail(row)">
          <template #title>
            <span class="custom-title hidden-text">
              <svg-icon icon-class="room" size="20" style="margin-right:5px" />{{row.roomCode+'房间'}}
            </span>
          </template>
          <template #label>
            <div class="custom-centent  hidden-text">
              <van-icon name="label-o" size="14" />
              <span style="margin-left:5px">{{row.schoolName +"|" +row.apartmentName+"|" +row.floorLayers}}</span>
            </div>
            <div class="custom-centent margin6 hidden-text">
              <van-icon name="user-circle-o" size="14" />
              <span style="margin-left:5px">舍长:{{row.stuName?row.stuName:''}}{{row.stuPhone?'('+row.stuPhone+')':''}}
              </span>
            </div>
            <div class="custom-centent margin6 hidden-text">
              <svg-icon icon-class="bank-line" size="14" /><span style="margin-left:5px">{{
               row.unitName?(showSecondUnit(row.unitName)? row.unitName.split(',')[0]:row.unitName):"未分配"
                }}</span>
            </div>
            <div class="custom-centent margin6 hidden-text" v-if="showSecondUnit(row.unitName)">
              <svg-icon icon-class="bank-line" size="14" /><span style="margin-left:5px">{{
               (row.unitName.split(',')[1]).trim()
                }}</span>
            </div>
          </template>
          <template #right-icon>
            <span v-if="row.status" class="audit success-color b-color-success">启用</span>
            <span v-else class="audit warning-color b-color-warning">停用</span>
            <div class="room-div">
              <div class="room-count">
                <span class="room-div-mian-text">{{row.intoCount}}</span>
                <span class="room-div-other-text">{{" / "+row.bedNum}}</span>
                <van-icon style="margin-left: 15px;" name="arrow" size="15" />
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  name: "FloorRoom",
  mixins: [initData],
  data() {
    return {
      value: "",
    };
  },
  created() {},
  methods: {
    beforeInit() {
      this.url = "/api/room/apk";
      this.sort = ["roomCode,asc"];
      this.query.apartmentId = this.$route.query.apartmentId;
      this.query.campusName = this.$route.query.campusName;
      this.query.floorLayers = this.$route.query.layer;
      return true;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    toDetail(data) {
      this.$router.push({
        path: "/gy/g04",
        query: {
          title: data.apartmentName + data.roomCode + "房间",
          id: data.id,
          type: 4,
        },
      });
    },
    // 检索
    onSearch() {},
    toStuSearch() {
      if (this.query.keyword) {
        this.$router.push({
          path: "/gy/g04",
          query: {
            title: this.$route.query.title,
            type: 3,
            floorCode: this.$route.query.floorCode,
            layer: this.$route.query.layer,
            stuName: this.query.keyword,
          },
        });
      } else {
        this.toQuery();
      }
    },
    showSecondUnit(str) {
      if (!str) {
        return false;
      }
      if (str.indexOf(",") > 0) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@/assets/styles/apartment/floor.scss";
</style>
